import { useEffect } from 'react'

export const useOutsideClick = (
  ref: any,
  isDropdownOpen: boolean,
  setIsDropdownOpen: (state: boolean) => void
) => {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsDropdownOpen(false)
      }
    }
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [isDropdownOpen])
}
