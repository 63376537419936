import { gql } from '../../__generated__/gql'

export const MARK_ANNOUNCEMENTS_AS_READ_MUTATION = gql(`
  mutation markAnnouncementsAsRead($announcementIds: [Int!]!) {
    markAnnouncementsAsRead(input: { announcementIds: $announcementIds }) {
      announcements {
        ...announcementFields
      }
    }
  }
`)
