import { gql } from '../../__generated__/gql'

export const PROCESS_ANNOUNCEMENT_ACTION_MUTATION = gql(`
  mutation processAnnouncementAction(
    $id: Int!
    $action: InvitationActionEnum!
  ) {
    processAnnouncementAction(input: { id: $id, action: $action }) {
      announcement {
        ...announcementFields
      }
    }
  }
`)
