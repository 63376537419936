import { gql } from '../../__generated__/gql'

export const TOGGLE_ANNOUNCEMENT_AS_READ_MUTATION = gql(`
  mutation toggleAnnouncementAsRead($id: Int!) {
    toggleAnnouncementAsRead(input: { id: $id }) {
      announcement {
        ...announcementFields
      }
    }
  }
`)
